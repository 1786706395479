import { get, post } from '@/utils/request'

// 登陆
export const login = data => {
  return post('/login', data, 'json')
}

// 获取用户数据
export const getUserInfo = (url, data) => {
  return post(url, data)
}

// 获取用户权限
export const getWebInfo = data => {
  return get('/privilege/query/user/web/info', data)
}
