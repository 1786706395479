<template>
  <div class="wrap">
    <el-card shadow="always" class="login-box">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label>
          <el-row type="flex" justify="center">
            <h1>Sports Data Center</h1>
          </el-row>
        </el-form-item>
        <el-form-item label="name :" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="Password :" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loginLoging"
            type="primary"
            @click="submitForm('ruleForm')"
            class="login-btn"
            >login</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .login-box {
    width: 500px;
    padding: 40px 80px 20px 0;
    margin-bottom: 80px;
    .login-btn {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>

<script>
import { login, getWebInfo } from '@/api/login'

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password！'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatename = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input Username！'))
      } else {
        callback()
      }
    }
    return {
      loginLoging: false,
      ruleForm: {
        password: '',
        name: ''
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        name: [{ validator: validatename, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loginLoging = true
          login({ ...this.ruleForm, systemId: 1 })
            .then(res => {
              this.$store.dispatch('user/login', res.data)
              this.$store.dispatch('user/infoList', res.data.webFunInfoList ? res.data.webFunInfoList : [])
              this.$router.push('/')
              this.$message({
                message: 'login success',
                type: 'success'
              })
              // getWebInfo({}).then(e => {
              //   if(e.code === 0) {
              //     this.$store.dispatch('user/infoList', e.data ? e.data.roleFunction[0].functionInfoList : [])
              //     this.$router.push('/')
              //   }
              // })
            })
            .finally(() => {
              this.loginLoging = false
            })
        } else {
          this.$message.error('error login!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
